<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="api"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card 
                    class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar 
                        class="secondary white--text pa-1">
                        <v-toolbar-title 
                            class="text-h5">
                            New Demonstration Request
                        </v-toolbar-title>
                    </v-toolbar>
                    <div 
                        class="mt-5">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="First Name"
                                    dense
                                    outlined
                                    v-model="fname">
        
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    label="Last Name"
                                    dense
                                    outlined
                                    v-model="lname">
        
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <div 
                            class="d-flex justify-content-start">
                            <v-text-field
                                dense 
                                outlined 
                                v-model="email"
                                label="Email"
                                type="email">
                            </v-text-field>
                        </div>
                        <div 
                            class="d-flex justify-content-start">
                            <v-text-field
                                dense
                                outlined 
                                v-model="mobile"
                                label="Mobile">
                            </v-text-field>
                        </div>
                        <div
                            class="d-flex justify-content-start">
                            <v-text-field
                                dense 
                                outlined 
                                v-model="company"
                                label="Company">
                                </v-text-field>
                        </div>
                        <div 
                            class="d-flex justify-end">
                            <v-btn 
                                color="primary" 
                                plain
                                @click="()=>{
                                    $router.go(-1);
                                }">
                                Cancel
                            </v-btn>
                            <v-btn
                                color="primary" 
                                :loading="api.isLoading"
                                @click="validateInput">
                                Confirm
                            </v-btn>
                        </div>
                    </div>


                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        fname:null,
        lname:null,
        email:null,
        mobile:null,
        company:null,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="addBookDemonstration") 
            {
                this.api.isSuccesful=true,
                this.api.success= "Demonstration Book Succesfully"
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
    //
    },
    methods: {
        fetch(){

        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createApi = this.create();
            this.$api.fetch(createApi);
        },
        cancelSubmit(){
            this.isPending = true;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/demonstration";
            let formData = new FormData;
            formData.append('fname',this.fname);
            formData.append('lname',this.lname);
            formData.append('email',this.email);
            formData.append('mobile',this.mobile);
            formData.append('company',this.company);
            tempApi.params = formData;
            return tempApi;
        },
    }
}
</script>